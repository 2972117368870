@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$plan2gether-colour: #2784D8;

$min-contrast-ratio: 3;

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $plan2gether-colour;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);


@import '../node_modules/bootstrap/scss/bootstrap.scss';


html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: 'Lato', sans-serif;
    background-color: #ebecee;
    background: linear-gradient(180deg, rgba(228,228,228,1) 0%, rgba(240,240,240,1) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

h1, h2, h3, h4, h5 {
    font-family: 'Raleway', sans-serif;
}

a {
    text-decoration: none;
}

.name {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: #122833;
}

.name-second {
    color: $plan2gether-colour;
}

.btn.badge {
    border: 0px;
}

.popover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.items-list {
    min-width: 200px;
    max-width: 320px;
    width: 100%;
}

.item-details {
    max-width: 640px;
    width: 100%;
}

.item-info-label {
    width: 135px;
    min-width: 135px;
    padding: 0.5em;
}

.item-info-details {
    min-width: 150px;
    padding: 0.5em;
}

@media (max-width: 767.98px) {
    .popover {
        left: 50% !important;
        transform: translateX(-50%) !important;
        position: fixed !important;
        width: 100% !important;
        top: 0px !important;
        will-change: transform !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.3), 0 0 0 200vh rgba(0, 0, 0, 0.5);
    }
    .popover .arrow {
        display: none;
    }
}

.btn-sm svg {
    width: 1em !important;
    height: 1em !important;
    transform: translateY(0.1em);
}

.bg-medium {
    background-color: #e1e3e4 !important;
}

@media print {
    div {
        break-inside: avoid;
    }
}
